import { useId, CSSProperties } from "react";
import dayjs from "dayjs";
import { normalizeLink } from "@/base/helpers/normalizeLink";
import LinkComponent from "@/components/elements/LinkComponent";
import truncate from "@/base/helpers/truncateString";
import Image from "@/components/elements/Image";
import * as styles from "./styles.module.scss";

interface PromoBlockProps {
  title?: string;
  description?: string;
  date?: string;
  heroImage?: StoryblokGatsbyImageAsset;
  url?: string;
  slug?: string;
  label?: string;
  disableDate?: boolean;
  disableImage?: boolean;
}

export function PromoBlock({
  title,
  description,
  date,
  heroImage,
  url,
  label,
  disableDate = false,
  disableImage = false,
}: PromoBlockProps) {
  const id = useId();
  const link = normalizeLink(url);
  const formattedDate = date ? dayjs(date).format("DD MMMM YYYY") : null;

  return (
    <article
      className={styles.article}
      style={{ "--clip-path": `url(#clip-path-${id})` } as CSSProperties}
    >
      <div className={styles.container}>
        {heroImage?.image && !disableImage && (
          <div className={styles.image}>
            <Image
              data={heroImage}
              fluid
              options={{ height: 152, width: 152 }}
            />
          </div>
        )}

        <div className={styles.content}>
          {title && <h2>{title}</h2>}

          {description && (
            <p className={styles.description}>{truncate(description, 15)}</p>
          )}

          <div className={styles.details}>
            {formattedDate && !disableDate && (
              <p>
                <span className={styles.date}>{formattedDate}</span>
              </p>
            )}

            {link && (
              <LinkComponent
                url={link.url}
                type={link.type}
                with_icon
                className={styles.link}
              >
                {label || "Read more"}
              </LinkComponent>
            )}
          </div>
        </div>

        <div className={styles.mask}>
          <PromoMask id={`clip-path-${id}`} />
        </div>
      </div>
    </article>
  );
}

function PromoMask({ id }: { id: string }) {
  return (
    <svg className={styles.promoMaskSvg}>
      <clipPath id={id} clipPathUnits="objectBoundingBox">
        <path d="M0.989,0.577 C0.987,0.544,0.996,0.512,0.993,0.481 C0.983,0.362,1,0.205,0.967,0.104 C0.949,0.059,0.918,0.051,0.89,0.041 C0.759,-0.004,0.621,0,0.488,0 C0.408,0,0.329,0.002,0.249,0.008 C0.18,0.013,0.125,0.005,0.059,0.037 C-0.009,0.071,-0.001,0.208,0.002,0.3 C0.006,0.456,0.001,0.613,0.008,0.769 C0.011,0.833,0.023,0.963,0.073,0.963 C0.195,0.965,0.319,0.988,0.44,0.978 C0.529,0.971,0.618,0.994,0.707,0.994 C0.757,0.993,0.98,1,0.994,0.912 C1,0.802,0.995,0.688,0.989,0.577"></path>
      </clipPath>
    </svg>
  );
}
