import VerticalCard from "@/components/elements/VerticalCard";

import * as styles from "./styles.module.scss";

type ScientificCardData = {
  title: string;
  excerpt?: string;
  heroImage?: StoryblokGatsbyImageAsset;
  labels?: LabelType[];
  categories: CategoryType[];
  publishedDate: string;
  url: string;
  getNameByValue: (value: CategoryType) => string;
};

interface ResourceListProps {
  title: string;
  list?: Array<ResourceComponent>;
  scientificList?: Array<ScientificCardData>;
}

const CTA_LABEL_TEXT: Record<string, string> = {
  "Reports & books": "Download",
  Videos: "Watch now",
  Infographics: "Download",
};

const BADGE_LABEL_TEXT: Record<string, string> = {
  "Reports & books": "PDF file",
  Videos: "Video",
  Infographics: "PDF file",
};

export const ResourceList = ({
  title,
  list,
  scientificList,
}: ResourceListProps) => {
  const badgeText = BADGE_LABEL_TEXT[title];
  const ctaLabelText = CTA_LABEL_TEXT[title];

  return (
    <div className={styles.listContainer}>
      <div className={styles.titleBox}>{title}</div>
      <div className="container">
        <div className={styles.list}>
          {Array.isArray(list) &&
            list.map((item, i) => (
              <VerticalCard
                badge={badgeText}
                key={`${title}_${item.title}${i}`}
                title={item.title}
                url={item.resource_asset?.filename}
                videoUrl={item.resource_video_url}
                excerpt={item.description}
                heroImage={item.image}
                ctaLabel={ctaLabelText}
                {...item}
              />
            ))}
          {Array.isArray(scientificList) &&
            scientificList.map((item, i) => (
              <VerticalCard key={`${title}_${item.title}${i}`} {...item} />
            ))}
        </div>
      </div>
    </div>
  );
};
