import { navigate } from "gatsby";
import clsx from "clsx";

import Input from "@/components/elements/Input";
import LinkComponent from "@/components/elements/LinkComponent";
import Button from "@/components/elements/Button";
import SearchAutocomplete from "@/components/elements/SearchAutocomplete";
import type { SearchAutocompleteOption } from "@/components/elements/SearchAutocomplete/types";

import * as styles from "./styles.module.scss";

interface SearchProps {
  searchQuery: string;
  setSearchQuery: (searchValue: string) => void;
  options: SearchAutocompleteOption[];
  isLoading: boolean;
  navigateTo: string;
  handleInputClick?: () => void;
  isMobile?: boolean;
}

// Default search component with autocomplete
export function Search({
  searchQuery,
  setSearchQuery,
  options,
  isLoading,
  navigateTo,
  handleInputClick,
  isMobile,
}: SearchProps) {
  const handleSearch = () => navigate(navigateTo);

  return (
    <SearchAutocomplete
      value={searchQuery}
      options={options}
      isLoading={isLoading}
      itemClassName={styles.listItem}
      dropdownClassName={styles.dropdownContainer}
    >
      {({ ref, ...rest }) => {
        return (
          <div className={styles.searchInnerWrapper}>
            <Input
              {...rest}
              //@ts-ignore
              ref={ref}
              type="text"
              placeholder="Search Alpro Foundation..."
              name="search"
              withIcon={
                <img
                  src="/svgs/search.svg"
                  alt="search"
                  data-svg="true"
                  className={styles.searchIcon}
                  onClick={handleSearch}
                />
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={handleInputClick}
            />
            <LinkComponent
              type="internal"
              url={navigateTo}
              className={clsx(styles.searchButton, isMobile && styles.searchButtonMobile)}
            >
              <Button variant="primary">Search</Button>
            </LinkComponent>
          </div>
        );
      }}
    </SearchAutocomplete>
  );
}

// Search component with autocomplete used in mobile search dialog
export function SearchMobile({ searchQuery, setSearchQuery, options, isLoading, navigateTo }: SearchProps) {
  const handleSearch = () => navigate(navigateTo);

  return (
    <SearchAutocomplete
      isOpen
      value={searchQuery}
      options={options}
      isLoading={isLoading}
      itemClassName={clsx(styles.listItem, styles.listItemMobile)}
      dropdownClassName={clsx(styles.dropdownContainer, styles.dropdownContainerMobile)}
    >
      {({ ref, ...rest }) => {
        return (
          <div className={styles.searchInnerWrapper}>
            <Input
              {...rest}
              //@ts-ignore
              ref={ref}
              type="text"
              placeholder="Search Alpro Foundation..."
              name="search"
              withIcon={<SearchIcon className={styles.searchIcon} onClick={handleSearch} />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        );
      }}
    </SearchAutocomplete>
  );
}
