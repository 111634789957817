import { formatStoryblokSlug } from "./formatStoryblokSlug";

export function normalizeLink(
  url: string | undefined
): Pick<LinkElement, "type" | "url"> | undefined {
  if (!url) {
    return undefined;
  }

  return url.startsWith("http")
    ? { url, type: "external" as LinkElementType }
    : { url: formatStoryblokSlug(url), type: "internal" as LinkElementType };
}
