// @ts-nocheck

import { useState } from "react";
import { useMedia } from "react-use";
import dayjs from "dayjs";
import { useLockBodyScroll } from "@/base/hooks/useBodyScrollLock";
import { formatStoryblokSlug } from "@/base/helpers/formatStoryblokSlug";
import RemarkRichTextComponents from "@/components/elements/RichText";
import PromoBlock from "@/components/elements/PromoBlock";
import { useAlgoliaSearch } from "@/base/hooks/useAlgoliaSearch";
import { getPages } from "./getPages";
import { Search } from "./components/Search";
import { SearchDialog } from "./components/SearchDialog";
import * as styles from "./styles.module.scss";

export function TwoColumnHero({ content, blocks, popular_searches }: TwoColumnHeroType) {
  const isMobile = useMedia("(max-width: 575px)", true);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const { pages } = getPages(blocks);

  const { query, results, isLoading, setState } = useAlgoliaSearch();

  const options = results.map((item) => ({
    label: item.su_title || item.seo.title,
    slug: formatStoryblokSlug(item.slug),
  }));

  const handleInputClick = () => {
    if (isMobile && !isDialogOpen) {
      setDialogOpen(true);
    }
  };

  const setSearchSuggestion = (suggestion: string) => {
    setState({ query: suggestion });

    if (isMobile) {
      setDialogOpen(true);
    }
  };

  const navigateTo = `/search?query=${encodeURIComponent(query)}`;

  useLockBodyScroll(isDialogOpen);

  if (isDialogOpen) {
    return (
      <SearchDialog
        setDialogOpen={setDialogOpen}
        query={query}
        results={options}
        isLoading={isLoading}
        navigateTo={navigateTo}
        setSearchQuery={(v) => setState({ query: v })}
      />
    );
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.grid}>
          <div className={styles.contentContainer}>
            {content && (
              <div className={styles.text}>
                <RemarkRichTextComponents content={content} />
              </div>
            )}

            <div className={styles.searchWrapper}>
              <img src="/svgs/seeds/almond.svg" alt="leaf" className={styles.leftDecoration} />
              <Search
                searchQuery={query}
                options={options}
                setSearchQuery={(v) => setState({ query: v })}
                isLoading={isLoading}
                isMobile={isMobile}
                handleInputClick={handleInputClick}
                navigateTo={navigateTo}
              />

              {!!popular_searches.length && (
                <div className={styles.popularsWrapper}>
                  <p>Recently popular:</p>
                  <div className={styles.populars}>
                    {popular_searches?.map((popular, index) => (
                      <button
                        key={index}
                        onClick={() => setSearchSuggestion(popular.text)}
                        aria-label={`Use suggested term: ${popular.text}`}
                      >
                        {popular.text}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.promosContainer}>
            <img src="/svgs/leafAuthor.svg" alt="leaf" className={styles.rightDecoration} />
            <div className={styles.promos}>
              {pages.map((page) =>
                page.component === "template_page" ? (
                  <PromoBlock
                    key={page._uid}
                    title={page.title || page.seo.title}
                    description={page.description || page.seo.description}
                    date={page.date}
                    url={page.url || page.full_slug}
                    heroImage={page.image || page.body?.[0].hero_image}
                    label={page.label}
                    disableDate={page.disableDate}
                    disableImage={page.disableImage}
                  />
                ) : (
                  <PromoBlock
                    key={page._uid}
                    title={page.title || page.su_title}
                    description={page.description || page.su_excerpt}
                    date={page.date || dayjs(page.su_date_of_publication).format("DD MMMM YYYY")}
                    url={page.url || page.full_slug}
                    heroImage={page.image || page.su_hero_img}
                    label={page.label}
                    disableDate={page.disableDate}
                    disableImage={page.disableImage}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
