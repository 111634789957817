import { useEffect } from "react";

export function useLockBodyScroll(isLocked: boolean) {
  useEffect(() => {
    if (isLocked) {
      document.documentElement.classList.add("block-scroll");
      document.body.classList.add("block-scroll");
    } else {
      document.documentElement.classList.remove("block-scroll");
      document.body.classList.remove("block-scroll");
    }
  }, [isLocked]);
}
