import Icon from "@/components/elements/Icon";
import LinkComponent from "@/components/elements/LinkComponent";
import MarkdownComponent from "@/components/elements/Markdown";
import RemarkRichTextComponents from "@/components/elements/RichText";
import SocialLink from "@/components/elements/SocialLink";
import * as styles from "./styles.module.scss";

export function ContactHero({
  contact_image,
  title,
  label,
  organisation_details,
  organisation_label,
  email,
  email_label,
  social_media_label,
  social_media_links,
}: ContactHeroType) {
  return (
    <section>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.contentWrapper}>
            <p className={styles.label}>{label}</p>
            {title && (
              <div className={styles.title}>
                <RemarkRichTextComponents content={title} />
              </div>
            )}
            <div className={styles.contactInfo}>
              <Icon name="green-leaf" className={styles.leafIcon} />
              <div>
                <span>{email_label}</span>
                {Array.isArray(email) && (
                  <LinkComponent {...email[0]} className={styles.email} />
                )}
              </div>
            </div>
            <div className={styles.contactInfo}>
              <Icon name="green-leaf" className={styles.leafIcon} />
              <div>
                <span>{organisation_label}</span>
                {organisation_details && (
                  <MarkdownComponent content={organisation_details} />
                )}
              </div>
            </div>
            <div className={styles.contactInfo}>
              <Icon name="green-leaf" className={styles.leafIcon} />
              <div>
                <span>{social_media_label}</span>
                {Array.isArray(social_media_links) &&
                  social_media_links.map((link) => (
                    <SocialLink
                      key={link.type}
                      {...link}
                      type={link.type}
                      className={styles.socialIcon}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div>
            <img src={contact_image?.filename} alt={contact_image?.alt} />
          </div>
        </div>
      </div>
    </section>
  );
}
