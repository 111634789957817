import { graphql, useStaticQuery } from "gatsby";
// eslint-disable-next-line import/order
import { useMemo } from "react";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import List from "@/components/elements/VerticalCardList";

import useDataSource from "@/base/hooks/useDataSource";
import * as styles from "./styles.module.scss";

export function RecentPublishedGrants({
  btn_label,
  description,
  heading,
}: RecentPublishedGrantsType) {
  const data = useStaticQuery<{
    recentScientificUpdates: {
      nodes: StoryblokStory[];
    };
  }>(graphql`
    query RecentPublishedGrantsQuery {
      recentScientificUpdates: allStoryblokEntry(
        filter: { field_component: { eq: "template_scientificUpdate" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
        limit: 3
      ) {
        nodes {
          content
          full_slug
        }
      }
    }
  `);

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const initialData = useMemo(() => {
    const recentScientificUpdates =
      !!data && data.recentScientificUpdates
        ? data.recentScientificUpdates.nodes
            .map((node) => {
              const {
                su_title,
                su_excerpt,
                su_date_of_publication,
                su_categories,
                su_labels,
                su_hero_img,
              } = JSON.parse(node.content) as ScientificUpdatePageTemplate;
              return {
                key: node.full_slug,
                url: `/${node.full_slug}`,
                publishedDate: su_date_of_publication,
                title: su_title,
                excerpt: su_excerpt,
                categories: su_categories,
                labels: su_labels,
                heroImage: su_hero_img,
                getNameByValue,
              };
            })
            .filter((item) => item.labels?.find((label) => label === "grant"))
        : [];
    return { recentScientificUpdates };
  }, [data]);

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.text}>
            <Heading level={2}>{heading}</Heading>
            <p>{description}</p>
          </div>
          {btn_label && (
            <Button variant="outlined" className={styles.button}>
              <LinkComponent
                type="internal"
                url="/scientific-updates?label=grant"
                with_icon
              >
                {btn_label}
              </LinkComponent>
            </Button>
          )}
        </div>
        <List list={initialData.recentScientificUpdates} />
      </div>
    </section>
  );
}
