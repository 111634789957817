import Heading from "@/components/elements/Heading";

import * as styles from "./styles.module.scss";

export function ResourcesHero({ title, description }: ResourcesHeroType) {
  return (
    <section className={styles.section}>
      <div className="container">
        <span className={styles.subheading}>Resources</span>
        <Heading level={1}>{title}</Heading>
        <p className={styles.description}>{description}</p>
      </div>
      <img src="/svgs/leaf.svg" alt="leaf" className={styles.leaf} />
      <img src="/svgs/bean.svg" alt="bean" className={styles.bean} />
    </section>
  );
}
