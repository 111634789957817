import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import useDataSource from "@/base/hooks/useDataSource";
import List from "@/components/elements/VerticalCardList";
import leaf from "@/assets/images/event-leaf.png";
import * as styles from "./styles.module.scss";

export function RecentEvents({ heading, btn_label }: RecentEventsType) {
  const data = useStaticQuery<{
    recentEvents: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query RecentEventsQuery {
      recentEvents: allStoryblokEntry(
        filter: { field_component: { eq: "template_event" } }
        sort: { fields: field_event_date_string, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            content
            full_slug
          }
        }
      }
    }
  `);
  const initialData = useMemo(() => {
    const recentEvents =
      !!data && data.recentEvents
        ? data.recentEvents.edges.map(({ node }) => ({
            ...(JSON.parse(node.content) as EventPageTemplate),
            full_slug: node.full_slug,
          }))
        : [];
    return { recentEvents };
  }, [data]);
  const { getNameByValue } = useDataSource<CategoryType>("categories");
  const recentEventsList = initialData.recentEvents?.map((item) => ({
    key: item._uid,
    title: item.event_title,
    publishedDate: item.event_date,
    excerpt: item.event_description,
    url: `/${item.full_slug}`,
    heroImage: item.event_thumbnail,
    labels: item.event_labels,
    categories: item.event_categories,
    getNameByValue,
  }));
  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.text}>
            {heading && <Heading level={2}>{heading}</Heading>}
          </div>
          {btn_label && (
            <Button variant="outlined" className={styles.button}>
              <LinkComponent type="internal" url="/events" with_icon>
                {btn_label}
              </LinkComponent>
            </Button>
          )}
        </div>
        <List list={recentEventsList} />
        <div className={styles.leaf}>
          <img src={leaf} alt="" />
        </div>
      </div>
    </section>
  );
}
