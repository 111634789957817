// extracted by mini-css-extract-plugin
export var article = "styles-module--article--7f8bd";
export var container = "styles-module--container--ee73f";
export var content = "styles-module--content--26013";
export var date = "styles-module--date--25592";
export var description = "styles-module--description--a63ad";
export var details = "styles-module--details--c806a";
export var image = "styles-module--image--9cc9c";
export var link = "styles-module--link--ecf8f";
export var mask = "styles-module--mask--282c9";
export var promoMaskSvg = "styles-module--promoMaskSvg--dfcf4";