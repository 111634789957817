import { graphql, Link, useStaticQuery } from "gatsby";
import dayjs from "dayjs";
import { useMemo } from "react";

import useDataSource from "@/base/hooks/useDataSource";
import Tag from "@/components/elements/Tag";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import MainScientificUpdateCard from "@/components/sections/RecentScientificUpdates/MainScientificUpdate";
import Image from "@/components/elements/Image";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";

import * as styles from "./styles.module.scss";

export function RecentScientificUpdates({
  heading,
  subheading,
  link_label,
}: RecentScientificUpdateType) {
  const data = useStaticQuery<{
    recentScientificUpdates: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query RecentScientificUpdateQuery {
      recentScientificUpdates: allStoryblokEntry(
        filter: { field_component: { eq: "template_scientificUpdate" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            content
            full_slug
          }
        }
      }
    }
  `);

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const initialData = useMemo(() => {
    const recentScientificUpdates =
      !!data && data.recentScientificUpdates
        ? data.recentScientificUpdates.edges.map(({ node }) => ({
            ...(JSON.parse(node.content) as ScientificUpdatePageTemplate),
            full_slug: node.full_slug,
          }))
        : [];
    return { recentScientificUpdates };
  }, [data]);

  const mostRecentUpdate = initialData.recentScientificUpdates[0];
  const recentUpdates = initialData.recentScientificUpdates.slice(1);

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.flex}>
          <div className={styles.content}>
            <Heading level={2}>
              <LinkComponent url="/scientific-updates" type="internal">
                {heading}
              </LinkComponent>
            </Heading>
            <p className={styles.subheading}>{subheading}</p>
          </div>
          <Button variant="outlined" className={styles.button}>
            <LinkComponent with_icon url="/scientific-updates" type="internal">
              {link_label}
            </LinkComponent>
          </Button>
        </div>
        <div className={styles.posts}>
          <MainScientificUpdateCard
            {...mostRecentUpdate}
            getNameByValue={getNameByValue}
          />
          <div className={styles.recentUpdates}>
            {recentUpdates.map((scientificUpdate) => (
              <div
                key={scientificUpdate._uid}
                className={styles.recentUpdatesCard}
              >
                <div className={styles.heroImage}>
                  {scientificUpdate.su_hero_img?.image ? (
                    <Image
                      data={scientificUpdate.su_hero_img}
                      fluid
                      options={{ width: 80, height: 80 }}
                    />
                  ) : (
                    <ImagePlaceholder />
                  )}
                </div>
                <div>
                  <div className={styles.details}>
                    <Link
                      to={scientificUpdate.full_slug}
                      className={styles.link}
                    >
                      <Heading level={4}>{scientificUpdate.su_title}</Heading>
                      <span>
                        {dayjs(scientificUpdate.su_date_of_publication).format(
                          "DD MMMM YYYY"
                        )}
                      </span>
                    </Link>
                    <div className={styles.categories}>
                      {Array.isArray(scientificUpdate.su_categories) && (
                        <Link
                          to={`/scientific-updates/${scientificUpdate.su_categories[0]}`}
                        >
                          <Tag
                            text={getNameByValue(
                              scientificUpdate.su_categories[0]
                            )}
                            size="small"
                          />
                        </Link>
                      )}
                      <Tag text="..." size="small" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
