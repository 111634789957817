// eslint-disable-next-line import/order
import { useLocation } from "@reach/router";

import Heading from "@/components/elements/Heading";
import RichText from "@/components/elements/RichText";
import FloatingBox from "@/components/elements/FloatingBox";

import * as styles from "./styles.module.scss";

export function PlantBasedEating({
  content,
  cta,
}: Pick<PlantBasedEatingType, "content" | "cta">) {
  const location = useLocation();

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.flexContainer}>
          <div className={styles.contentContainer}>
            <Heading level={1}>Plant-based eating</Heading>
            <div className={styles.richtextContainer}>
              <RichText content={content} />
            </div>
          </div>
          <FloatingBox
            postUrl={location.pathname.slice(1, location.pathname.length)}
            su_cta={cta}
          />
        </div>
      </div>
    </section>
  );
}
