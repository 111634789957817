import dayjs from "dayjs";
import { Link } from "gatsby";

import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";

import Tag from "@/components/elements/Tag";
import ClipPath from "@/components/elements/ClipPath";
import LinkComponent from "@/components/elements/LinkComponent";
import * as styles from "./styles.module.scss";

export function MainScientificUpdateCard({
  su_title,
  su_categories,
  su_date_of_publication,
  su_excerpt,
  su_hero_img,
  full_slug,
  su_labels,
  getNameByValue,
}: ScientificUpdatePageTemplate & {
  full_slug: string;
  getNameByValue: (value: CategoryType) => string;
}) {
  return (
    <div className={styles.cardWrap}>
      <div className={styles.cardImageWrapper}>
        <div className={styles.labels}>
          {Array.isArray(su_labels) &&
            su_labels.map((label) => (
              <Tag type={label} key={label} size="small" />
            ))}
        </div>
        <ClipPath className={styles.clipPath} version={2}>
          {su_hero_img?.image ? (
            <Image
              data={su_hero_img}
              fluid
              options={{
                width: 540,
                height: 300,
              }}
            />
          ) : (
            <ImagePlaceholder />
          )}
        </ClipPath>
      </div>
      <div className={styles.cardDetails}>
        <Link to={full_slug} className={styles.link}>
          {su_date_of_publication && (
            <span>{dayjs(su_date_of_publication).format("DD MMMM YYYY")}</span>
          )}
          <Heading level={4}>{su_title}</Heading>
          <p className={styles.excerpt}>{su_excerpt}</p>
        </Link>
        <div className={styles.categories}>
          {Array.isArray(su_categories) &&
            su_categories.map((category) => (
              <Link to={`/scientific-updates/${category}`} key={category}>
                <Tag text={getNameByValue(category)} size="small" />
              </Link>
            ))}
        </div>
        <LinkComponent
          url={full_slug}
          type="internal"
          with_icon
          className={styles.readMore}
        >
          Read more
        </LinkComponent>
      </div>
    </div>
  );
}
