import { graphql, PageProps } from "gatsby";
import { useStoryblok } from "@/base/lib/storyblok";
import StoryblokComponent from "@/base/utils/connectSbComponent";

import MainLayout from "@/components/layout/Main";
import { storyblokEditable } from "@storyblok/react";

type PageData = {
  story: StoryblokStory;
  header: StoryblokStory;
  footer: StoryblokStory;
  poll: StoryblokStory;
  mainBanner: StoryblokStory;
};

export function Page({ data, location }: PageProps<PageData>) {
  const { story, footer, header, poll } = data;
  const parsedStory = useStoryblok<PageTemplate>(story, location);

  const {
    content: { body, seo },
  } = parsedStory;
  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;
  const { formId } = JSON.parse(poll.content) as PollTemplate;

  return (
    <MainLayout header={parsedHeader} footer={parsedFooter} seo={seo} formId={formId} type="website">
      {Array.isArray(body) &&
        body.map((section) => (
          <div key={section._uid} {...storyblokEditable(section)}>
            <StoryblokComponent blok={section} />
          </div>
        ))}
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      content
    }
    header: storyblokEntry(field_component: { eq: "template_header" }) {
      content
    }
    footer: storyblokEntry(field_component: { eq: "template_footer" }) {
      content
    }
    poll: storyblokEntry(field_component: { eq: "template_poll" }) {
      content
    }
  }
`;

export default Page;
