import { getSlugWithSlashes } from "./getSlugWithSlashes";

/**
 * Removes "pages/" from storyblok slug
 * Makes sure slash is prepended + appended
 */
export const formatStoryblokSlug = (slug: string) => {
  let _slug = slug;

  _slug = slug?.replace("pages/", "");

  _slug = getSlugWithSlashes(_slug);

  return _slug;
};
