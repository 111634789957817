import { FunctionComponent } from "react";

import SurveyBanner from "@/components/elements/SurveyBanner";
import AwardsHero from "@/components/sections/AwardsHero";
import ContactHero from "@/components/sections/ContactHero";
import CurrentFundedResearch from "@/components/sections/CurrentFundedResearch";
import EventsHero from "@/components/sections/EventsHero";
import FeaturedScientificBoard from "@/components/sections/FeaturedScientificBoard";
import MainHero from "@/components/sections/MainHero";
import NewsCta from "@/components/sections/NewsCta";
import NewsHero from "@/components/sections/NewsHero";
import Newsletter from "@/components/sections/Newsletter";
import PaperFormEmbedSection from "@/components/sections/PaperFormEmbed";
import PastEvents from "@/components/sections/PastEvents";
import PlantBasedEating from "@/components/sections/PlantBasedEating";
import RecentEvents from "@/components/sections/RecentEvents";
import RecentPublishedGrants from "@/components/sections/RecentPublishedGrants";
import RecentScientificUpdates from "@/components/sections/RecentScientificUpdates";
import ResearchGrantsHero from "@/components/sections/ResearchGrantsHero";
import Resources from "@/components/sections/Resources";
import ResourcesHero from "@/components/sections/ResourcesHero";
import ScientificBoardList from "@/components/sections/ScientificBoardList";
import ScientificUpdateList from "@/components/sections/ScientificUpdateList";
import TextContent from "@/components/sections/TextContent";
import TwoColumnHero from "@/components/sections/TwoColumnHero";

const ComponentNotFound = ({ component }: PropType<Props, "blok">) => (
  <h3>
    The component <strong style={{ color: "red" }}>{component}</strong> has not
    been created yet.
  </h3>
);

interface Props {
  blok:
    | MainHeroType
    | ContactHeroType
    | TextContentType
    | RecentScientificUpdateType
    | NewsletterSectionType
    | ScientificUpdateListType
    | FeaturedScientificBoardType
    | RecentEventsType
    | ScientificBoardListType
    | ResearchGrantsType
    | RecentPublishedGrantsType
    | PlantBasedEatingType
    | CurrentFundedResearchType
    | AwardsType
    | EventsHeroType
    | PastEventsType
    | ResourcesHeroType
    | ResourcesType
    | NewsHeroType
    | NewsCtaType
    | PaperFormEmbedType
    | SurveyBanner
    | TwoColumnHeroType;
}

const StoryblokComponent: FunctionComponent<Props> = ({ blok }) => {
  // check if component is defined above
  switch (blok.component) {
    case "section_contactHero":
      return <ContactHero {...blok} />;
    case "section_featuredScientificBoard":
      return <FeaturedScientificBoard {...blok} />;
    case "section_mainHero":
      return <MainHero {...blok} />;
    case "section_survey":
      return <SurveyBanner {...blok} />;
    case "section_newsletter":
      return <Newsletter {...blok} />;
    case "section_recentEvents":
      return <RecentEvents {...blok} />;
    case "section_recentScientificUpdates":
      return <RecentScientificUpdates {...blok} />;
    case "section_textContent":
      return <TextContent {...blok} />;
    case "section_scientificBoardList":
      return <ScientificBoardList {...blok} />;
    case "section_scientificUpdatesList":
      return <ScientificUpdateList {...blok} />;
    case "section_researchGrantsHero":
      return <ResearchGrantsHero {...blok} />;
    case "section_recentPublishedGrants":
      return <RecentPublishedGrants {...blok} />;
    case "section_plantBasedEating":
      return <PlantBasedEating {...blok} />;
    case "section_currentFundedResearch":
      return <CurrentFundedResearch {...blok} />;
    case "section_awards":
      return <AwardsHero {...blok} />;
    case "section_eventsHero":
      return <EventsHero {...blok} />;
    case "section_pastEvents":
      return <PastEvents {...blok} />;
    case "section_resources":
      return <Resources {...blok} />;
    case "section_resourcesHero":
      return <ResourcesHero {...blok} />;
    case "section_newsCta":
      return <NewsCta {...blok} />;
    case "section_newsHero":
      return <NewsHero {...blok} />;
    case "section_paperformEmbed":
      return <PaperFormEmbedSection {...blok} />;
    case "section_twoColumnHero":
      return <TwoColumnHero {...blok} />;
    default:
      return <ComponentNotFound {...(blok as Props["blok"])} />;
  }
};

export default StoryblokComponent;
