import { useEffect, useState } from "react";
import { StoryblokResult } from "storyblok-js-client";
import queryString from "query-string";

import { Storyblok } from "@/base/lib/storyblok";
import useDataSource from "@/base/hooks/useDataSource";
import useModal from "@/base/hooks/useModal";
import Heading from "@/components/elements/Heading";
import ModalForm from "@/components/elements/ModalForm/index";
import Modal from "@/components/elements/RichText/RichTextSubscriberForm/Modal/Modal";
import VerticalCard from "@/components/elements/VerticalCard";
import HeaderScientificUpdate from "@/components/sections/ScientificUpdateList/HeaderScientificUpdate";
import Pagination from "@/components/elements/Pagination";
import { useLocation } from "@reach/router";

import * as styles from "./styles.module.scss";

const amountPerPage = process.env.GATSBY_SU_OFFSET
  ? parseInt(process.env.GATSBY_SU_OFFSET, 10)
  : 9;

export function ScientificUpdateList({
  heading,
  subheading,
}: ScientificUpdateListType) {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState<Array<HitSUTemplate>>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  const totalPages = Math.ceil(total / amountPerPage);

  const { handleStopPropagation, toggleModal, isOpen, setIsOpen } = useModal();

  const renderModal = () => {
    try {
      toggleModal();
      typeof window !== "undefined" &&
        window.localStorage.setItem(
          "showSubscriptionModal",
          JSON.stringify(true)
        );
    } catch (error) {
      if (error instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
    }
  };

  const toShowModal: string | null =
    (typeof window !== "undefined" &&
      window.localStorage.getItem("showSubscriptionModal")) ||
    null;

  const showModal = toShowModal && JSON.parse(toShowModal);

  useEffect(() => {
    if (!showModal) {
      renderModal();
    }
  }, []);

  async function getData(label?: string) {
    const prepareFilters = () => {
      if (label) {
        return {
          component: { in: "template_scientificUpdate" },
          su_labels: { all_in_array: label },
        };
      }
      return {
        component: { in: "template_scientificUpdate" },
      };
    };

    const {
      data,
      total,
    }: Omit<StoryblokResult, "data"> & {
      data: { stories: ParsedStoryblokStory<ScientificUpdatePageTemplate>[] };
    } = await Storyblok.get(`cdn/stories`, {
      version: "published",
      filter_query: prepareFilters(),
      page: page + 1,
      cv: Date.now(),
      per_page: amountPerPage,
      sort_by: "content.su_date_of_publication:desc",
    });

    const results = Array.isArray(data.stories)
      ? data.stories.map((story) => ({
          slug: `/${story.full_slug}`,
          id: story.id,
          ...story.content,
        }))
      : [];

    setSearchResults(results);
    setTotal(total);
  }

  useEffect(() => {
    const { label } = queryString.parse(location.search);
    if (typeof label === "string") {
      getData(label);
    } else {
      getData();
    }
  }, [page, location.search]);

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  return (
    <section className={styles.wrapper}>
      {isOpen && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          className={styles.modal}
          onClick={() => setIsOpen(false)}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="modal"
        >
          <Modal handleStopPropagation={handleStopPropagation} isOpen={isOpen}>
            <ModalForm onClick={() => setIsOpen(false)} />
          </Modal>
        </div>
      )}

      <HeaderScientificUpdate
        heading={heading}
        subheading={subheading}
        setPage={setPage}
        dataSourceCategory="categories"
      />

      <div className="container">
        <div className={styles.headingContent}>
          <Heading level={1}>{heading}</Heading>
          <p>{subheading}</p>
        </div>
        <div className={styles.updatesList}>
          {searchResults.map((scientificUpdate) => (
            <VerticalCard
              key={scientificUpdate._uid}
              title={scientificUpdate.su_title}
              publishedDate={scientificUpdate.su_date_of_publication}
              heroImage={scientificUpdate.su_hero_img}
              excerpt={scientificUpdate.su_excerpt}
              labels={scientificUpdate.su_labels}
              categories={scientificUpdate.su_categories}
              url={scientificUpdate.slug}
              getNameByValue={getNameByValue}
            />
          ))}
        </div>
        <Pagination
          setPage={setPage}
          currentPage={page}
          totalPages={totalPages}
          containerId="su-box"
        />
      </div>
      <img src="/img/grey-bg.png" alt="" className={styles.greyBg} />
    </section>
  );
}
