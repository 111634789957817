import { navigate } from "gatsby";
import Autocomplete from "react-autocomplete";
import LinkComponent from "@/components/elements/LinkComponent";
import type { SearchAutocompleteOption } from "./types";
import * as styles from "./styles.module.scss";

interface SearchAutocompleteProps {
  value: string;
  options: SearchAutocompleteOption[];
  itemClassName?: string;
  dropdownClassName?: string;
  isLoading?: boolean;
  isOpen?: boolean;
  children: (props: React.HTMLProps<HTMLInputElement>) => React.ReactNode;
}

export function SearchAutocomplete({
  value,
  options,
  itemClassName,
  isLoading,
  dropdownClassName,
  children,
  isOpen,
}: SearchAutocompleteProps) {
  const isMenuOpen = !!options?.length || isLoading;

  return (
    <Autocomplete
      {...(isOpen && { open: true })}
      wrapperStyle={{}}
      wrapperProps={{
        className: styles.inputContainer,
      }}
      value={value}
      getItemValue={(item) => item.slug}
      items={options}
      renderItem={(item, isHighlighted) => (
        <li key={item.slug} data-highlighted={isHighlighted} className={itemClassName}>
          <LinkComponent type="internal" url={item.slug} className={styles.link}>
            {item.label && <span>{item.label}</span>}
          </LinkComponent>
        </li>
      )}
      onSelect={(slug) => navigate(slug)}
      renderInput={(props) => children(props)}
      renderMenu={(items) =>
        isMenuOpen ? (
          <div className={dropdownClassName}>
            {!!items.length && <ul>{items}</ul>}

            {isLoading && (
              <div className={styles.spinner}>
                <img src={`svgs/spinner.svg`} alt="Loading" className={styles.animateSpin} />
              </div>
            )}
          </div>
        ) : (
          <></>
        )
      }
    />
  );
}
