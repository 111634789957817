import RichText from "@/components/elements/RichText";
import beans from "@/assets/images/beans-bg.png";
import topLeaf from "@/assets/images/terms-top-leaf.png";
import bottomLeaf from "@/assets/images/terms-bottom-leaf.png";

import * as styles from "./styles.module.scss";

export function TextContent({ content }: TextContentType) {
  return (
    <section className={styles.wrapper}>
      <img src={beans} alt="beans" className={styles.beansBg} />
      <img src={topLeaf} alt="top leaf" className={styles.topLeaf} />
      <img src={bottomLeaf} alt="bottom leaf" className={styles.bottomLeaf} />

      <div className="container">
        <div className={styles.content}>
          <RichText content={content}></RichText>
        </div>
      </div>
    </section>
  );
}
