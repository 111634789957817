import { useRef } from "react";
import useClientPagination from "@/base/hooks/useClientPagination";
import useDataSource from "@/base/hooks/useDataSource";
import HeaderScientificUpdate from "../ScientificUpdateList/HeaderScientificUpdate";
import ResourceList from "./ResourceList";
import * as styles from "./styles.module.scss";
import PaginatedResourcesComponent from "./withPagination";

export function Resources({
  books,
  infographics,
  videos,
  scientific_updates,
}: ResourcesType) {
  const { getNameByValue } = useDataSource<ResourcesDataType>("resources");

  const ITEMS_PER_PAGE = 6;

  const bookRef = useRef(null);
  const videosRef = useRef(null);
  const infographicsRef = useRef(null);

  const {
    paginatedArray: paginatedBooks,
    pageCount: booksPageCount,
    handlePageClick: handleBookPageClick,
    showPagination: showBooksPagination,
  } = useClientPagination(
    books as ResourceComponent[],
    bookRef,
    ITEMS_PER_PAGE
  );

  const {
    paginatedArray: paginatedInfographics,
    pageCount: infoGraphicsPageCount,
    handlePageClick: handleInfographicsPageClick,
    showPagination: showInfographicsPagination,
  } = useClientPagination(
    infographics as ResourceComponent[],
    infographicsRef,
    ITEMS_PER_PAGE
  );

  const {
    paginatedArray: paginatedVideos,
    pageCount: videosPageCount,
    handlePageClick: handleVideosPageClick,
    showPagination: showVideosPagination,
  } = useClientPagination(
    videos as ResourceComponent[],
    videosRef,
    ITEMS_PER_PAGE
  );

  const suPages =
    Array.isArray(scientific_updates) &&
    scientific_updates.map(({ content, full_slug }) => {
      const {
        su_title,
        su_excerpt,
        su_hero_img,
        su_labels,
        su_categories,
        su_date_of_publication,
      } = content;
      return {
        title: su_title,
        excerpt: su_excerpt,
        heroImage: su_hero_img,
        labels: su_labels,
        categories: su_categories,
        publishedDate: su_date_of_publication,
        url: `/${full_slug}`,
        getNameByValue: getNameByValue,
      };
    });

  return (
    <section className={styles.section}>
      <HeaderScientificUpdate dataSourceCategory="resources" />

      <div ref={bookRef}>
        <PaginatedResourcesComponent.BooksResourcesComponent
          title="Reports & books"
          list={paginatedBooks}
          showPagination={showBooksPagination}
          handlePageClick={handleBookPageClick}
          pageCount={booksPageCount}
        />
      </div>

      {Array.isArray(suPages) && suPages.length > 0 && (
        <ResourceList title="Scientific updates" scientificList={suPages} />
      )}
      <div ref={videosRef}>
        <PaginatedResourcesComponent.VideosResourcesComponent
          title="Videos"
          list={paginatedVideos}
          showPagination={showVideosPagination}
          handlePageClick={handleVideosPageClick}
          pageCount={videosPageCount}
        />
      </div>

      <div ref={infographicsRef}>
        <PaginatedResourcesComponent.InfographicsResourcesComponent
          title="Infographics"
          list={paginatedInfographics}
          showPagination={showInfographicsPagination}
          handlePageClick={handleInfographicsPageClick}
          pageCount={infoGraphicsPageCount}
        />
      </div>
    </section>
  );
}
