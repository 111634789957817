import mask from "@/assets/images/banner-mask.png";
import { useMediaQuery } from "@/base/hooks/useMediaQuery";
import Image from "@/components/elements/Image";

import Button from "@/components/elements/Button";
import Icon from "@/components/elements/Icon";
import LinkComponent from "@/components/elements/LinkComponent";
import RichText from "@/components/elements/RichText";

import { computeAndFormatDate } from "@/base/helpers/computeAndFormatDate";
import ClipPath from "@/components/elements/ClipPath/index";
import * as styles from "./styles.module.scss";

export function SurveyBanner({
  content,
  image,
  readmore_cta,
  closing_date,
  closing_date_text,
}: SurveyBanner) {
  const MEDIA_QUERY = "(max-width: 600px)";

  const isHandheldDevice = useMediaQuery(MEDIA_QUERY);

  const IMAGE_OPTIONS = {
    width: isHandheldDevice ? 380 : 550,
    height: 350,
  };

  return (
    <div className="container">
      <div className={styles.wrapper}>
        <div className={styles.img}>
          {isHandheldDevice ? (
            // TODO: install bejamas-storyblok-image (bsi) and replace images
            <>
              <img
                src={mask}
                alt=""
                className={styles.mask}
                style={{
                  display: isHandheldDevice ? "none" : "block",
                }}
              />
              <ClipPath version={2} className={styles.clipPath}>
                <img
                  src={image.filename}
                  alt={image.alt}
                />
              </ClipPath>
            </>
          ) : (
            <div>
              <img src={mask} alt="" className={styles.mask} />
              <ClipPath version={2} className={styles.clipPath}>
                <Image
                  data={image.filename}
                  fixed
                  alt={image.alt}
                  options={IMAGE_OPTIONS}
                />
              </ClipPath>
            </div>
          )}
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <RichText content={content} />
          </div>

          {closing_date_text && closing_date && (
            <div className={styles.deadline}>
              <div className={styles.calendar}>
                <Icon name="calendar" />
              </div>

              <div className={styles.event}>
                <p>{closing_date_text}</p>
                <p>{computeAndFormatDate(closing_date)}</p>
              </div>
            </div>
          )}

          <div>
            {readmore_cta && (
              <Button variant="outlined" className={styles.button}>
                <LinkComponent {...readmore_cta[0]} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
