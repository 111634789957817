import { createPortal } from "react-dom";
import LinkComponent from "@/components/elements/LinkComponent";
import Button from "@/components/elements/Button";
import { SearchAutocompleteOption } from "@/components/elements/SearchAutocomplete/types";
import Icon from "@/components/elements/Icon";
import { SearchMobile } from "../Search";
import * as styles from "./styles.module.scss";

interface SearchDialogProps {
  query: string;
  results: SearchAutocompleteOption[];
  isLoading: boolean;
  navigateTo: string;
  setDialogOpen: (value: boolean) => void;
  setSearchQuery: (value: string) => void;
}

export function SearchDialog({
  setDialogOpen,
  query,
  results,
  isLoading,
  navigateTo,
  setSearchQuery,
}: SearchDialogProps) {
  const handleClose = () => {
    setDialogOpen(false);
    setSearchQuery("");
  };

  return createPortal(
    <div className={styles.searchDialogWrapper}>
      <div className={styles.searchDialogContainer}>
        <div className={styles.dialogHeader}>
          <p>Search</p>

          <button
            onClick={handleClose}
            aria-label="Close search"
            className={styles.close}
          >
            <Icon name="close" />
          </button>

          <img src="/svgs/navbarMobileBg.svg" alt="background" data-svg="true" />
        </div>

        <div className={styles.inputWrapper}>
          <SearchMobile
            searchQuery={query}
            options={results}
            setSearchQuery={setSearchQuery}
            isLoading={isLoading}
            navigateTo={navigateTo}
          />
        </div>

        <div className={styles.searchButtonWrapper}>
          <LinkComponent
            type="internal"
            url={`/search?query=${encodeURIComponent(query)}`}
            className={styles.searchButton}
          >
            <Button variant="primary" onClick={() => setDialogOpen(false)}>
              Search
            </Button>
          </LinkComponent>
        </div>
      </div>
    </div>,
    document.body
  );
}
