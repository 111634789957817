import { useCallback } from "react";
import { useDebounce, useSetState } from "react-use";
import { useAlgoliaIndex } from "@/base/hooks/useAlgoliaIndex";

export function useAlgoliaSearch() {
  const { isIndexReady, index } = useAlgoliaIndex();

  const [state, setState] = useSetState<{
    query: string;
    results: Record<string, any>[];
    isLoading: boolean;
  }>({
    query: "",
    results: [],
    isLoading: false,
  });

  const { query, results, isLoading } = state;

  const fetchData = useCallback(async () => {
    if (query) {
      setState({
        isLoading: true,
      });

      if (index && isIndexReady) {
        const results = await index.search(query);
        setState({
          results: results?.hits,
          isLoading: false,
        });
      }
    } else {
      setState({
        results: [],
        isLoading: false,
      });
    }
  }, [index, query]);

  useDebounce(
    () => {
      if (index) {
        setState({
          isLoading: true,
        });
        fetchData();
      }
    },
    1000,
    [query]
  );

  return {
    query,
    results,
    isLoading,
    setState,
  };
}
