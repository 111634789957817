import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

export const getPages = (blocks: PromoBlockComponent[] = []) => {
  const data = useStaticQuery<{
    recentScientificUpdates: {
      edges: {
        node: StoryblokStory;
      }[];
    };
    pages: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query ScientificUpdateQuery {
      recentScientificUpdates: allStoryblokEntry(
        filter: { field_component: { eq: "template_scientificUpdate" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
      ) {
        edges {
          node {
            content
            full_slug
            uuid
          }
        }
      }

      pages: allStoryblokEntry(
        filter: { field_component: { eq: "template_page" } }
      ) {
        edges {
          node {
            content
            full_slug
            uuid
          }
        }
      }
    }
  `);

  const recentScientificUpdates = useMemo(() => {
    const updates =
      !!data && data.recentScientificUpdates
        ? data.recentScientificUpdates.edges.map(({ node }) => ({
            ...(JSON.parse(node.content) as ScientificUpdatePageTemplate),
            full_slug: node.full_slug,
            uuid: node.uuid,
          }))
        : [];
    return updates;
  }, [data]);

  const pages = useMemo(() => {
    const pages =
      !!data && data.pages
        ? data.pages.edges.map(({ node }) => ({
            ...(JSON.parse(node.content) as PageTemplate),
            full_slug: node.full_slug,
            uuid: node.uuid,
          }))
        : [];
    return pages;
  }, []);

  const selectedPages = blocks.length
    ? blocks.map((block) => {
        const relatedPage = [...pages, ...recentScientificUpdates].find(
          (page) => page.uuid === block.related_page
        );
        return {
          title: block.title,
          description: block.description,
          date: block.date,
          image: block.image?.image ? block.image : null,
          label: block.cta_label,
          url: block.url,
          disableDate: block.disable_date,
          disableImage: block.disable_image,
          ...relatedPage,
        };
      })
    : [];

  const pagesToDisplay =
    selectedPages.length > 0
      ? selectedPages
      : recentScientificUpdates.slice(0, 3).map((page) => ({
          label: "",
          ...page,
        }));

  return {
    pages: pagesToDisplay,
  };
};
