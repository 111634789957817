// extracted by mini-css-extract-plugin
export var contentContainer = "styles-module--contentContainer--237bb";
export var grid = "styles-module--grid--dda7e";
export var leftDecoration = "styles-module--leftDecoration--94b85";
export var populars = "styles-module--populars--45add";
export var popularsWrapper = "styles-module--popularsWrapper--1cdd7";
export var promos = "styles-module--promos--3d824";
export var promosContainer = "styles-module--promosContainer--e63ed";
export var rightDecoration = "styles-module--rightDecoration--65e8d";
export var searchWrapper = "styles-module--searchWrapper--33aae";
export var section = "styles-module--section--b7d34";
export var text = "styles-module--text--9c3f4";