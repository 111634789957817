import ClientPagination from "@/components/elements/Pagination/ClientPagination";

type TWithPagination = {
  title: string;
  list: ResourceComponent[];
  showPagination: boolean;
  handlePageClick: (event: { selected: number }) => void;
  pageCount: number;
};

const withPagination = (
  Component: React.FunctionComponent<Pick<TWithPagination, "title" | "list">>
) => {
  return ({
    title,
    list,
    showPagination,
    handlePageClick,
    pageCount,
  }: TWithPagination) => {
    return (
      <>
        <Component title={title} list={list} />
        {showPagination && (
          <ClientPagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        )}
      </>
    );
  };
};

export default withPagination;
